import { api } from '.';
import { PRODUCT_RESOURCE, COMMENTS_RESOURCE } from '@/services/keys';

export const getResources = (productId, commentId) => ({
  [PRODUCT_RESOURCE]: productId,
  [COMMENTS_RESOURCE]: commentId,
});

/**
 *
 * comment to update
 * @param {string} productId
 * @return {Promise.<Object>}
 */
export async function updateProductComment(productId, comment) {
  const partialUrl = api.createUrl(getResources(productId, comment.id));
  const { data } = await api.update(partialUrl, comment.payload());
  return data;
}
