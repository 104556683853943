var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full text-sm" },
    [
      _c("asterix-comments", {
        attrs: {
          "comment-list": _vm.commentList,
          tags: _vm.tags,
          loading: _vm.isLoading,
        },
        on: {
          "create-or-update": _vm.onCreateOrUpdateComment,
          delete: _vm.deleteComment,
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [_vm._v("Comments")]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }