<template>
  <div class="w-full text-sm">
    <asterix-comments
      :comment-list="commentList"
      :tags="tags"
      :loading="isLoading"
      @create-or-update="onCreateOrUpdateComment"
      @delete="deleteComment"
    >
      <template #title>Comments</template>
    </asterix-comments>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';
import { COMMENT_TAGS } from '@/model/shared/Tags';
import Product from '@/entities/ThirdParty/Product';
import metaInfo from '@/mixins/common/metaInfo';
import AsterixComments from '@/components/organisms/shared/asterixComments/AsterixComments';
import { createProductComment } from '@/services/modules/ThirdParty/product/createProductComment';
import { updateProductComment } from '@/services/modules/ThirdParty/product/updateProductComment';
import { removeProductComment } from '@/services/modules/ThirdParty/product/deleteProductComment';

export default {
  name: 'ProductComments',
  components: {
    AsterixComments,
  },
  mixins: [metaInfo],
  props: {
    entity: {
      type: Product,
      default: () => null,
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    itemToRemove: null,
    tags: COMMENT_TAGS,
  }),
  computed: {
    commentList() {
      return this.entity?.comments || [];
    },
  },
  methods: {
    ...mapActions({ createToast: CREATE_TOAST }),
    metaTitleReplacement() {
      return { product: this.entity?.name } || {};
    },
    async deleteComment(comment) {
      try {
        await removeProductComment(this.entity.id, comment.id);
        const toast = Toast.success('Comment deleted', 'Comment was deleted successfully');
        this.createToast(toast);

        this.$emit('delete');
      } catch (e) {
        this.createToast(Toast.error('Comment not deleted', e.message));
      }
    },
    async onCreateOrUpdateComment(comment) {
      const updateOrCreateText = comment?.id ? 'updated' : 'created';
      try {
        if (comment?.id) {
          await updateProductComment(this.entity.id, comment);
          this.$emit('update');
        } else {
          await createProductComment(this.entity.id, comment);
          this.$emit('create');
        }
        const toast = Toast.success(`Comment ${updateOrCreateText}`, `Comment was ${updateOrCreateText} successfully`);
        this.createToast(toast);
      } catch (e) {
        this.createToast(Toast.error(`Comment not ${updateOrCreateText}`, e.message));
      }
    },
  },
};
</script>

<style scoped>
::v-deep #date-picker-multiple > div {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
::v-deep #date-picker-multiple {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
}

::v-deep .p-1.flex.flex-row.items-start.max-w-full.p-2 {
  display: none;
  padding: 0;
}

.search-svg-absolute {
  display: inline-block;
  position: absolute;
  top: 12px;
  right: 8px;
}

.form-button {
  max-width: 75px;
}

.no-data-cnt {
  min-height: 400px;
}
</style>
