import { api } from '.';
import { PRODUCT_RESOURCE, COMMENTS_RESOURCE } from '@/services/keys';

export const getResources = (productId, commentId) => ({
  [PRODUCT_RESOURCE]: productId,
  [COMMENTS_RESOURCE]: commentId,
});

/**
 *
 * @param {string} productId
 * @param {Promise.<Comment>} product comment
 */
export async function createProductComment(productId, comment) {
  const partialUrl = api.createUrl(getResources(productId, comment.id));
  const { data } = await api.create(partialUrl, comment.payload());
  return data;
}
