import { api } from '.';
import { PRODUCT_RESOURCE, COMMENTS_RESOURCE } from '@/services/keys';

export const getResources = (productId, commentId) => ({
  [PRODUCT_RESOURCE]: productId,
  [COMMENTS_RESOURCE]: commentId,
});

/**
 *
 * @param {string} productId
 * @param {string} commentId
 * @returns {Promise<Object>}
 */
export async function removeProductComment(productId, commentId) {
  const partialUrl = api.createUrl(getResources(productId, commentId));
  const { data } = await api.delete(partialUrl);
  return data;
}
